<template>
  <div>
    <div class="card">
      <el-form :model="form" :inline="true" class="form">
        <el-form-item>
          <el-button type="primary" @click="addVisible = true" v-if="!edit && !add">新增销售目标</el-button>
          <el-button  @click="handleCanel" v-if="add">取消新增</el-button>
          <template v-if="!add">
            <el-button type="primary" v-if="!edit" @click="handleEdit" :disabled="task_list.length ? false : true">编辑</el-button>
            <el-button v-else @click="handleCanel">取消编辑</el-button>
          </template>
        </el-form-item>
        <el-form-item label="销售年月">
          <el-date-picker
            :disabled="edit || add"
            v-model="form.target_month"
            type="year"
            placeholder="选择年"
            value-format="yyyy"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="门店">
          <el-select
            v-model="form.hierarchy_id"
            filterable
            :disabled="userInfo.hierarchy_type_id === 40 || edit || add"
          >
            <template v-for="item in merchantList">
              <el-option
                :value="item.hierarchy_id"
                :label="item.hierarchy_name"
                :key="item.hierarchy_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 20px;" v-if="!edit && !add">
          <div style="display: flex;">
            <el-button type="primary" @click="handleSearch" style="margin-left: 10px;">查询</el-button>
            <el-button @click="handleReset">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="box">
      <div class="left">
        <div class="block">
          <div class="item" style="width: 25%;">
            <span class="title">上年销售总金额</span>：
            <span>{{sale_year.last_sale_amount}}</span>
          </div>
          <div class="item" style="width: 25%;">
            <span class="title">计划增长率</span>：
            <span v-if="!edit && !add">{{sale_year.percent_plan_amount}}%</span>
            <el-input
              class="no-number"
              type="number"
              style="width: 150px;"
              v-if="edit || add"
              v-model="sale_year.percent_plan_amount"
              @input="handleInput($event, { target: 'sale_amount',  source: 'percent_plan_amount',  last: 'last_sale_amount', type: 1 })"
            >
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item" style="width: 30%;">
            <span class="title">销售目标总金额</span>：
            <span v-if="!edit && !add">{{sale_year.sale_amount}}</span>
            <el-input
              class="no-number"
              type="number"
              style="width: 180px;"
              v-if="edit || add"
              v-model="sale_year.sale_amount"
              @input="handleInput($event, { target: 'percent_plan_amount',  source: 'sale_amount', last: 'last_sale_amount', type: 2 })"
            ></el-input>
          </div>
          <div class="item" style="width: 20%;" v-if="Number(sale_year['surplus_amount']) >= 0">
            <span class="title">未分配目标金额</span>：
            <span>{{sale_year.surplus_amount || 0}}</span>
          </div>
          <div class="item" style="width: 20%;" v-else>
            <span class="title">超出分配目标金额</span>：
            <span>{{Math.abs(sale_year.surplus_amount) || 0}}</span>
          </div>
          <div class="item" style="width: 25%;">
            <span class="title">上年新增会员数</span>：
            <span>{{sale_year.last_member_number}}</span>
          </div>
          <div class="item" style="width: 25%;">
            <span class="title">计划增长率</span>：
            <span v-if="!edit && !add">{{sale_year.percent_plan_member}}%</span>
            <el-input
              class="no-number"
              style="width: 150px;"
              v-if="edit || add"
              v-model="sale_year.percent_plan_member"
              @input="handleInput($event, { target: 'member_number',  source: 'percent_plan_member', last: 'last_member_number',  type: 1 })"
            >
              <template slot="append">%</template>
            </el-input>
          </div>
          <div class="item" style="width: 30%;">
            <span class="title" style="margin-right: 27px;">新增会员数：</span>
            <span v-if="!edit && !add">{{sale_year.member_number}}</span>
            <el-input
              style="width: 180px;"
              v-if="edit || add"
              v-model="sale_year.member_number"
              @input="handleInput($event, { target: 'percent_plan_member',  source: 'member_number', last: 'last_member_number', type: 2 })"
            ></el-input>
          </div>
          <div class="item" style="width: 20%;" v-if="Number(sale_year.surplus_member_number) >= 0">
            <span class="title">未分配会员数</span>：
            <span>{{sale_year.surplus_member_number || 0}}</span>
          </div>
          <div class="item" style="width: 20%;" v-else>
            <span class="title">超出分配会员数</span>：
            <span>{{Math.abs(sale_year.surplus_member_number) || 0}}</span>
          </div>
        </div>
        <div class="table">
          <div class="btns" v-if="edit || add">
            <el-button type="primary" @click="handleFenPei({ type: 1 })">按上年比例分配</el-button>
            <el-button type="primary" @click="handleFenPei({ type: 2 })">按月平均分配</el-button>
          </div>
          <el-table
            :data="task_list"
            v-loading="loading"
            :height="height"
            :row-class-name="tableRowClass"
            @row-click="handleRowClick"
          >
            <template v-for="(item, index) in columnList">
              <el-table-column
                align="center"
                :key="index"
                :prop="item.field_alias"
                :label="item.field_text"
                :width="item.t_width"
                :filters="item.filed_status"
              >
                <template slot-scope="scope">
                  <template v-if="item.field_alias === 'sale_amount'">
                    <span
                      v-if="!edit && !add"
                    >{{(scope.row[item.field_alias] || scope.row[item.field_alias] === 0) ? scope.row[item.field_alias]: '--'}}</span>
                    <el-input
                      v-else
                      class="no-number"
                      type="number"
                      @input="handleSum($event, scope.row, { source: 'sale_amount', target: 'task_list' })"
                      v-model="scope.row[item.field_alias]"
                      @focus="handleFocus2($event, scope.row.index)"
                    ></el-input>
                  </template>
                  <template v-else-if="item.field_alias === 'member_number'">
                    <span
                      v-if="!edit && !add"
                    >{{(scope.row[item.field_alias] || scope.row[item.field_alias] === 0) ? scope.row[item.field_alias]: '--'}}</span>
                    <el-input
                      v-else
                      class="no-number"
                      type="number"
                      @input="handleSum($event, scope.row, { source: 'member_number', target: 'task_list' })"
                      v-model="scope.row[item.field_alias]"
                      @focus="handleFocus2($event, scope.row.index)"
                    ></el-input>
                  </template>
                  <template
                    v-else
                  >{{(scope.row[item.field_alias] || scope.row[item.field_alias] === 0) ? scope.row[item.field_alias]: '--'}}</template>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <div class="right" v-if="currIndex !== 0 && task_list.length">
        <div class="block">
          <div class="item" style="width: 20%;">
            <span class="title">年月：</span>
            <span>{{task_list[currIndex - 1].employee.info.timeline}}</span>
          </div>
          <div class="item" style="width: 33%;">
            <span class="title">月目标总金额：</span>
            <span>{{task_list[currIndex - 1].employee.info.sale_amount}}</span>
          </div>
          <div
            class="item"
            style="width: 33%;"
            v-if="Number(task_list[currIndex - 1].employee.info.surplus) >= 0"
          >
            <span class="title">未分配目标金额：</span>
            <span>{{task_list[currIndex - 1].employee.info.surplus}}</span>
          </div>
          <div class="item" style="width: 33%;" v-else>
            <span class="title">超出分配目标金额：</span>
            <span>{{Math.abs(task_list[currIndex - 1].employee.info.surplus)}}</span>
          </div>
          <div class="item" style="width: 20%;"></div>
          <!-- <div class="item" style="width: 33%;">
            <span class="title">目标新增总会员数：</span>
            <span>12345687</span>
          </div>
          <div class="item" style="width: 33%;">
            <span class="title">未分配会员数：</span>
            <span>12345687</span>
          </div>-->
        </div>
        <div class="table">
          <div class="btns" v-if="edit || add">
            <el-button type="primary" @click="handleAdd">批量添加</el-button>
            <el-button type="primary" @click="handleFenPeiEm">按平均分配</el-button>
          </div>
          <el-table
            :data="task_list[currIndex - 1].employee.list"
            stripe
            v-loading="loading"
            :height="height"
            :row-class-name="handleRowIndex"
            @selection-change="handleSelectionChange2"
          >
            <!-- <el-table-column v-if="edit || add" align="center" type="selection" width="55"></el-table-column> -->
            <template v-for="(item, index) in columnList2">
              <el-table-column
                align="center"
                :key="index"
                :prop="item.field_alias"
                :label="item.field_text"
                :width="item.t_width"
                :filters="item.filed_status"
              >
                <template slot-scope="scope">
                  <template v-if="item.field_alias === 'sale_amount'">
                    <span
                      v-if="!edit && !add"
                    >{{(scope.row[item.field_alias] || scope.row[item.field_alias] === 0) ? scope.row[item.field_alias]: '--'}}</span>
                    <el-input
                      v-else
                      v-model="scope.row[item.field_alias]"
                      type="number"
                      class="no-number"
                      :id="'input'+ scope.row.index"
                      @keydown.tab.native="handleKeyDown($event, scope.row.index + 1)"
                      @focus="handleFocus($event)"
                      @input="handleSum($event, scope.row, { source: 'sale_amount', target: 'employee_list' })"
                    ></el-input>
                  </template>
                  <template v-else-if="item.field_alias === 'member_number'">
                    <span
                      v-if="!edit && !add"
                    >{{(scope.row[item.field_alias] || scope.row[item.field_alias] === 0) ? scope.row[item.field_alias]: '--'}}</span>
                    <el-input v-else v-model="scope.row[item.field_alias]"></el-input>
                  </template>
                  <template
                    v-else
                  >{{(scope.row[item.field_alias] || scope.row[item.field_alias] === 0) ? scope.row[item.field_alias]: '--'}}</template>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="操作" align="center" v-if="edit">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  style="color: #F56C6C;"
                  @click="handleDele(scope.row.index)"
                >移除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="bottom" v-if="edit || add">
      <div>
        <el-button type="primary" @click="handleSave" :loading="saveLoading">保存</el-button>
        <el-button @click="handleCancelSave">取消</el-button>
      </div>
    </div>
    <el-dialog title="新增销售目标" :visible.sync="addVisible" width="30%" :before-close="handleClose">
      <el-form :model="form" label-width="100px">
        <el-form-item label="销售年月" required="">
          <el-date-picker v-model="formAdd.sale_year" type="year" placeholder="选择年" value-format="yyyy"></el-date-picker>
        </el-form-item>
        <el-form-item label="门店" required="">
          <el-select
            v-model="formAdd.hierarchy_id"
            filterable
            :disabled="userInfo.hierarchy_type_id === 40"
          >
            <template v-for="item in merchantList">
              <el-option
                :value="item.hierarchy_id"
                :label="item.hierarchy_name"
                :key="item.hierarchy_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCanel2">取 消</el-button>
        <el-button type="primary" @click="handleAddTarget">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="售货员"
      :visible.sync="saleVisible"
      width="50%"
      :before-close="handleClose"
      v-if="saleVisible"
    >
      <div class="flex">
        <el-input
          placeholder="请输入内容"
          clearable
          v-model="search_text"
          style="width: 350px;"
          @keydown.enter.native="getEmployee"
        >
          <template slot="append">
            <el-button type="primary" size="small" icon="el-icon-search" @click="getEmployee">搜索</el-button>
          </template>
        </el-input>
      </div>
      <div class="table" style="margin-top: 10px;">
        <el-table
          v-loading="employeeLoading"
          ref="multipleTable"
          :data="tableData3.list"
          :row-class-name="handleRowIndex"
          row-key="index"
          @selection-change="handleSelectionChange"
          height="450"
        >
          <el-table-column
            type="selection"
            align="center"
            width="55"
            :selectable="handleSelectable"
            :reserve-selection="true"
          ></el-table-column>
          <template v-for="item in columnList3">
            <el-table-column
              align="center"
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
            ></el-table-column>
          </template>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData3.total"
          :page-size="limit"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saleVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleTrue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import numeral from "numeral";
import { getMerchantListReq } from "@/api/goods/historyInv/index";
import {
  getSaleTargetReq,
  getSaleInfoReq,
  getEmployeeReq,
  getLastPercentReq,
  updateReq,
  addReq,
  issetTargetReq,
} from "@/api/work/target/saleTarget.js";
import storage from "good-storage";
export default {
  name: "SaleTarget",
  data() {
    return {
      employeeLoading: false,
      height: '630',
      searchForm: {},
      formAdd: {
        target_month: "", //年 或者 月
        hierarchy_id: ""
      },
      saveLoading: false,
      multipleSelection2: [],
      multipleSelection: [],
      timer: null,
      search_text: "", // 售货员搜素的关键字
      sale_year: {},
      task_list: [
        {
          employee: {
            info: {},
            list: []
          }
        }
      ],
      saleInfo: {
        sale_year: {},
        task_list: []
      }, // 销售目标信息
      currIndex: 0,
      userInfo: {},
      page: 1,
      limit: this.$store.state.pageSizes[0],
      loading3: false,
      tableData3: {
        list: [],
        total: 0
      },
      columnList3: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "姓名", field_alias: "realname" },
        { field_text: "职位", field_alias: "role_name" },
        { field_text: "手机号", field_alias: "username" }
      ],
      saleVisible: false,
      addVisible: false,
      add: false,
      edit: false,
      loading2: false,
      loading: false,
      merchantList: [], // 门店列表
      userInfo: {},
      form: {
        target_month: "", //年 或者 月
        hierarchy_id: ""
      },
      columnList: [
        { field_text: "门店", field_alias: "shop_name" },
        { field_text: "年月", field_alias: "timeline" },
        { field_text: "上年月销售金额", field_alias: "last_sale_amount" },
        { field_text: "月目标金额", field_alias: "sale_amount" },
        { field_text: "上年月新增会员数", field_alias: "last_member_number" },
        { field_text: "月新增会员数", field_alias: "member_number" }
      ],
      columnList2: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "售货员", field_alias: "realname" },
        { field_text: "月目标金额", field_alias: "sale_amount" }
        // { field_text: "月新增会员数", field_alias: "member_number" }
      ],
      tableData2: []
    };
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMerchantList();
  },
  watch: {
    edit(val) {
      if (val) {
        this.height = '530'
        console.log('触发');
      } else {
        this.height = '630'
      }
    },
    add(val) {
      if (val) {
        this.height = '530'
      } else {
        this.height = '630'
      }
    },
  },
  methods: {
    handleCanel2() {
      this.addVisible = false;
    },
    // 取消保存
    handleCancelSave() {
      if (this.edit) {
        this.getSaleTarget();
      } else {
        this.handleReset();
      }
    },
    // 编辑
    handleEdit() {
      this.form = {
        target_month: this.sale_year.year,
        hierarchy_id: '' + this.task_list[0].hierarchy_id,
      };
      this.edit = true;
    },
    // 取消新增
    handleCanel() {
      this.add = false;
      this.getMerchantList();
    },
    handleAddTarget() {
      // 请求接口
      issetTargetReq({
        ...this.formAdd,
      })
        .then((res) => {
          if (res.code === 1) {
            this.form = { target_month: this.formAdd.sale_year, hierarchy_id: this.formAdd.hierarchy_id };
            this.searchForm = { ...this.form };
            this.addVisible = false;
            this.add = true;
            this.saleInfo = res.data;
            this.sale_year = res.data.sale_year;
            this.task_list = res.data.task_list;
            this.currIndex = 1;
          }
        });

    },
    // 编辑保存
    handleSave() {
      this.saveLoading = true;
      if (this.edit) {
        updateReq({ sale_year: this.sale_year, task_list: this.task_list })
        .then((res) => {
          if (res.code === 1) {
            this.saveLoading = false;
            this.eidt = false;
            this.getSaleTarget();
          }
        })
        .catch(() => {
          this.saveLoading = false;
        })
      } else if (this.add) {
        addReq({ sale_year: this.sale_year, task_list: this.task_list })
        .then((res) => {
          if (res.code === 1) {
            this.saveLoading = false;
            this.add = false;
            this.getSaleTarget();
          }
        })
        .catch(() => {
          this.saveLoading = false;
        })
      }

    },
    handleKeyDown(e, nextIndex) {
      e.preventDefault();
      const inputDom = document.getElementById('input' + nextIndex);
      this.$nextTick(() => {
        try {
          inputDom.focus();
        } catch (error) {
        }
      })
    },
    handleFocus2(e, index) {
      this.currIndex = index;
      e.currentTarget.select();
    },
    handleFocus(e) {
      e.currentTarget.select();
    },
    // 员工平均分配
    handleFenPeiEm() {
      // 把勾选的加起来平均分配
      let employee = this.task_list[this.currIndex - 1].employee;
      let total = this.task_list[this.currIndex - 1].employee.info.sale_amount;
      const average_sale_amount = numeral(total / employee.list.length).format('0.00');
      const surplus_sale_amount = numeral(total - Number(average_sale_amount) * (employee.list.length - 1)).format('0.00');
      this.task_list[this.currIndex - 1].employee.list = employee.list.map((item, index) => {
        let obj = {};
        if ((index + 1) < employee.list.length) {
          obj = { ...item, sale_amount: average_sale_amount }
        } else {
          obj = { ...item, sale_amount: surplus_sale_amount }
        }
        return obj;
      })
      employee.info.surplus = 0;
    },
    // 分配
    handleFenPei({ type }) {
      if (type === 1) {
        this.loading = true;
        let data = {
          hierarchy_id: this.searchForm.hierarchy_id,
          last_year: this.searchForm.target_month - 1,
        }
        if (this.add) {
          data = {
            ...data,
            ...this.sale_year,
          }
        }
        getLastPercentReq({
          ...data,
        })
          .then(res => {
            if (res.code === 1) {
              this.loading = false;
              this.task_list = this.task_list.map(task => {
                let obj = {};
                for (let i = 0; i < Object.keys(res.data).length; i += 1) {
                  const key = Object.keys(res.data)[i];
                  if (task.timeline == key) {
                    obj = { ...task, ...res.data[key] };
                    obj.employee.info.sale_amount = res.data[key].sale_amount;
                    let total_employee = 0;
                    obj.employee.list.forEach(item => {
                      total_employee += Number(item.sale_amount);
                    });
                    const surplus_employee = numeral(
                      Number(obj.employee.info.sale_amount) - total_employee
                    ).format("0.00");
                    obj.employee.info.surplus = surplus_employee;
                  }
                }
                return obj;
              });
              // 计算
              let total_surplus_amount = 0;
              let total_surplus_member_number = 0;
              this.task_list.forEach(item => {
                total_surplus_amount += Number(item.sale_amount);
                total_surplus_member_number += Number(item.member_number);
              });
              this.sale_year.surplus_amount = numeral(
                this.sale_year.sale_amount - total_surplus_amount
              ).format("0.00");
              this.sale_year.surplus_member_number = numeral(
                this.sale_year.member_number - total_surplus_amount
              ).format("0");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else if (type === 2) {
        // 除不尽问题，会员不能为小数
        // 平均月目标金额
        const average_sale_amount = numeral(
          Number(this.sale_year.sale_amount) / 12
        ).format("0.00");
        const last_sale_amount = numeral(
          this.sale_year.sale_amount - average_sale_amount * 11
        ).format("0.00");
        // 平均会员数
        const average_member_number = numeral(
          Number(this.sale_year.member_number) / 12
        ).format("0");
        const last_member_number = numeral(
          this.sale_year.member_number - average_member_number * 11
        ).format("0");
        this.task_list = this.task_list.map((item, index) => {
          let obj = {};
          if (index <= 10) {
            obj = {
              ...item,
              sale_amount: average_sale_amount,
              member_number: average_member_number
            };
            obj.employee.info.sale_amount = average_sale_amount;
          } else {
            obj = {
              ...item,
              sale_amount: last_sale_amount,
              member_number: last_member_number
            };
            obj.employee.info.sale_amount = last_sale_amount;
          }
          let total_employee = 0;
          obj.employee.list.forEach(item => {
            total_employee += Number(item.sale_amount);
          });
          const surplus_employee = numeral(
            Number(obj.employee.info.sale_amount) - total_employee
          ).format("0.00");
          obj.employee.info.surplus = surplus_employee;
          return obj;
        });
        this.sale_year.surplus_amount = 0;
        this.sale_year.surplus_member_number = 0;
      }
    },
    // 计算合并
    handleSum(e, row, { source, target }) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setTimeout(() => {
        if (!row[source]) {
          row[source] = "0";
        }
        if (source === "member_number") {
          row[source] = numeral(row[source]).format("0");
        } else {
          row[source] = row[source].replace(
            /^(\-)*(\d+)\.(\d\d).*$/,
            "$1$2.$3"
          );
        }
        if (row.employee) {
          row.employee.info.sale_amount = row.sale_amount;
          // 员工未分配目标
          let total_employee = 0;
          row.employee.list.forEach(item => {
            total_employee += Number(item.sale_amount);
          });
          const surplus_employee = numeral(
            Number(row.employee.info.sale_amount) - total_employee
          ).format("0.00");
          row.employee.info.surplus = surplus_employee;
        }
        if (target === "task_list") {
          if (source === "sale_amount") {
            let total = 0;
            this.task_list.forEach(item => {
              total += Number(item[source]);
            });
            const surplus = Number(this.sale_year.sale_amount) - total;
            this.sale_year = {
              ...this.sale_year,
              surplus_amount: numeral(surplus).format("0.00")
            };
          } else if (source === "member_number") {
            let total = 0;
            this.task_list.forEach(item => {
              total += Number(item[source]);
            });
            const surplus = Number(this.sale_year.member_number) - total;
            this.sale_year = {
              ...this.sale_year,
              surplus_member_number: numeral(surplus).format("0")
            };
          }
        } else if (target === "employee_list") {
          if (source === "sale_amount") {
            let total = 0;
            const employee = this.task_list[this.currIndex - 1].employee;
            employee.list.forEach(item => {
              total += Number(item[source]);
            });
            console.log(total);
            const surplus = Number(employee.info.sale_amount) - total;
            employee.info = {
              ...employee.info,
              surplus: numeral(surplus).format("0.00")
            };
          }
        }
      }, 500);
    },
    // 批量添加售货员
    handleAdd() {
      this.tableData3 = {
        list: [],
        total: 0,
      };
      this.saleVisible = true;
      // 请求员工列表
      this.getEmployee();
      // this.$nextTick(() => {
      //   const list = this.task_list[this.currIndex - 1].employee.list;
      //   this.toggleSelection(list);
      // });
    },
    //  toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach(row => {
    //       console.log(this.$refs.multipleTable)
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    handleTrue() {
      let list = this.task_list[this.currIndex - 1].employee.list;
      this.multipleSelection.forEach(item => {
        item.sale_amount = 0;
        list.push(item);
      });
      this.saleVisible = false;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange2(val) {
      this.multipleSelection2 = val;
      console.log(this.multipleSelection2);
    },
    // 移除
    handleDele(index) {
      const list = this.task_list[this.currIndex - 1].employee.list;
      list.forEach((item, _index) => {
        if (index === _index + 1) {
          list.splice(_index, 1);
        }
      });
      let total = 0;
      const employee = this.task_list[this.currIndex - 1].employee;
      employee.list.forEach(item => {
        total += Number(item["sale_amount"]);
      });
      const surplus = Number(employee.info.sale_amount) - total;
      employee.info = {
        ...employee.info,
        surplus: numeral(surplus).format("0.00")
      };
    },
    handleSelectable(row) {
      const list = this.task_list[this.currIndex - 1].employee.list;
      for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        if (item.store_user_id == row.store_user_id) {
          return false;
        }
      }
      return true;
    },
    // 自动计算
    handleInput(e, obj) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      const sale_year = this.sale_year;
      this.timer = setTimeout(() => {
        if (obj.source === "member_number") {
          sale_year[obj.source] = numeral(sale_year[obj.source]).format("0");
        } else {
          sale_year[obj.source] = sale_year[obj.source].replace(
            /^(\-)*(\d+)\.(\d\d).*$/,
            "$1$2.$3"
          );
        }
        if (obj.type === 1) {
          if (!Number(sale_year[obj.last])) {
            this.$message.warning("上年销售总金额为0，计划增长率为100%");
            sale_year[obj.source] = "100.00";
            return;
          }
          if (!sale_year[obj.source]) {
            sale_year[obj.source] = "0.00";
          }
          sale_year[obj.target] = numeral(
            Number(sale_year[obj.last]) *
              (Number(sale_year[obj.source]) / 100 + 1)
          ).format("0.00");
          // sale_year[obj.source] = numeral(sale_year[obj.source]).format('0.00');
        }
        if (obj.type === 2) {
          if (!Number(sale_year[obj.last])) {
            // this.$message.warning("上年销售总金额为0，计划增长率为100%");
            sale_year[obj.target] = "100.00";
            return;
          }
          sale_year[obj.target] = numeral(
            (Number(sale_year[obj.source]) / Number(sale_year[obj.last]) - 1) *
              100
          ).format("0.00");
          // sale_year[obj.source] = numeral(sale_year[obj.source]).format('0.00');
        }
      }, 500);
    },
    // 获取员工列表
    getEmployee() {
      this.employeeLoading = true;
      getEmployeeReq({
        search_text: this.search_text,
        hierarchy_id: this.form.hierarchy_id,
        page: this.page,
        limit: this.limit
      }).then(res => {
        this.employeeLoading = false;
        if (res.code === 1) {
          this.tableData3 = res.data;
        }
      })
      .catch(() => {
        this.employeeLoading = false;
      });
    },
    handleRowIndex({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    handleRowClick(row) {
      this.currIndex = row.index;
    },
    tableRowClass({ row, rowIndex }) {
      row.index = rowIndex + 1;
      if (this.currIndex === row.index) {
        return "tr-bg";
      }
    },
    getSaleInfo() {
      getSaleInfoReq({
        sale_year: this.form.target_month,
        hierarchy_id: this.form.hierarchy_id
      }).then(res => {
        if (res.code === 1) {
          console.log(res.data);
        }
      });
    },
    // 行点击
    handleRow(row, column, event) {
      this.currIndex = row.index;
    },
    // 获取当前年
    getCurrentYear() {
      const date = new Date();
      const year = date.getFullYear();
      this.form = { ...this.form, target_month: "" + year };
      this.searchForm = { ...this.form };
      this.formAdd.hierarchy_id = this.form.hierarchy_id;
    },
    // 获取当前年数据
    getSaleTarget() {
      this.loading = true;
      this.edit = false;
      this.add = false;
      getSaleTargetReq({ ...this.form })
        .then(res => {
          if (res.code === 1) {
            this.loading = false;
            this.saleInfo = res.data;
            this.sale_year = res.data.sale_year;
            this.task_list = res.data.task_list;
            if (!this.currIndex) {
              this.currIndex = 1;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 改变当前分页
    handleCurrentChange(val) {
      this.page = val;
      this.getEmployee();
    },
    // 改变分页条数
    handleSizeChange(limit) {
      this.limit = limit;
      this.getEmployee();
    },
    handleClose() {
      this.saleVisible = false;
      this.addVisible = false;
    },
    handleReset() {
      this.currIndex = 0;
      this.getCurrentYear();
      this.getSaleTarget();
    },
    handleSearch() {
      this.currIndex = 0;
      this.searchForm = { ...this.form };
      this.getSaleTarget();
    },
    getMerchantList() {
      getMerchantListReq().then(res => {
        if (res.code === 1) {
          this.merchantList = res.data;
          // 如果是门店
          this.form.hierarchy_id = res.data[0].hierarchy_id;
          // 获取当前年
          this.getCurrentYear();
          // this.getSaleInfo();
          this.getSaleTarget();
          // 请求员工列表
          // this.getEmployee();
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-input-group__append {
  background: #409eff;
  color: white;
}
/deep/ .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
/deep/ .el-table .tr-bg {
  background: #fdf5e6;
}
.flex {
  display: flex;
  justify-content: flex-end;
}
.bottom {
  background: white;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #ddd;
}
/deep/ .el-table tr {
  cursor: pointer;
}
.btns {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.table {
  // margin-top: 10px;
  background: white;
}
.card {
  // width: 130%;
}
.form {
  display: flex;
  /deep/ .el-form-item {
    margin-bottom: 0px !important;
  }
}
.box {
  display: flex;
  margin-top: 10px;
  .left {
    width: 60%;
    flex-shrink: 0;
    overflow: hidden;
  }
  .right {
    width: 40%;
    flex-shrink: 0;
    flex: 1;
    margin-left: 10px;
    overflow: hidden;
  }
  .block {
    border: 1px solid #ddd;
    background: white;
    padding: 20px;
    padding-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    box-sizing: border-box;
    .item {
      width: 25%;
      height: 40px;
      display: flex;
      margin-top: 10px;
      align-items: center;
      .title {
        position: relative;
        color: black;
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background: #409eff;
          position: absolute;
          top: 7px;
          left: -10px;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
