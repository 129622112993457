import { post } from '@/utils/request';

// 销售目标列表
const getSaleTargetReq = (data) => post({
    url: '/store/hierarchy.HierarchySaleTarget/list',
    data: {
        ...data,
    },
});

// 销售数据
const getSaleInfoReq = (data) => post({
    url: '/store/hierarchy.HierarchySaleTarget/getSaleInfo',
    data: {
        ...data,
    },
});

// 员工列表
const getEmployeeReq = (data) => post({
    url: '/store/authority.User/getSaleEmployeelist',
    data: {
        ...data,
    },
});

// 上年分配比例
const getLastPercentReq = (data) => post({
    url: '/store/hierarchy.HierarchySaleTarget/getLastPercent',
    data: {
        ...data,
    },
});

// 编辑 
const updateReq  = (data) => post({
    url: '/store/hierarchy.HierarchySaleTarget/update',
    data: {
        ...data,
    },
});

// 是否设置年目标
const issetTargetReq  = (data) => post({
    url: '/store/hierarchy.HierarchySaleTarget/issetTarget',
    data: {
        ...data,
    },
});

// 新增
const addReq  = (data) => post({
    url: '/store/hierarchy.HierarchySaleTarget/add',
    data: {
        ...data,
    },
});

export {
    getLastPercentReq,
    getSaleTargetReq,
    getSaleInfoReq,
    getEmployeeReq,
    updateReq,
    issetTargetReq,
    addReq,
}
